<template>
    <div class="section-wrapper bfti-dashboard-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <div class="form-wrapper edu-trust-schol-wrapper">
                <b-card :title="$t('teaGardenPduService.online_edducation_app')">
                    <b-card-text style="padding: 2rem 2rem 1rem;">
                        <b-row>
                            <b-col sm="4">
                                <div class="filter-wrapper">
                                    <b-form-group :label="$t('globalTrans.scholarship_type')" class="mb-0">
                                        <b-form-radio-group
                                            name="radio-inline"
                                            v-model="search.scholarship_type_id"
                                            :options="scholarshipTypeList"
                                            >
                                            </b-form-radio-group>
                                    </b-form-group>
                                </div>
                            </b-col>
                            <b-col sm="8">
                                <b-row>
                                    <b-col sm="6">
                                        <router-link :to="{name: 'tea_garden_service.pdu.online_education_application_list', query:{ trust_type: this.search.scholarship_type_id, status: 5, limit: 100} }">
                                        <div class="card-item-squ-col">
                                            <div class="card-item-squ">
                                                <div class="card-top schools">
                                                    <i class="ri-building-fill"></i>
                                                    <p>{{ $t('teaGardenDashboard.no_of_schools') }}</p>
                                                </div>
                                                <div class="card-bottom">
                                                    <h3>{{ search.scholarship_type_id === 1 ? $n(totPrimarySchools) : $n(numberOfSchools) }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col sm="6">
                                         <router-link :to="{name: 'tea_garden_service.pdu.online_education_application_list', query:{ trust_type: this.search.scholarship_type_id, status: 5, limit: 100} }">
                                            <div class="card-item-squ-col">
                                                <div class="card-item-squ">
                                                    <div class="card-top students">
                                                        <i class="ri-user-star-fill"></i>
                                                        <p>{{ $t('teaGardenDashboard.no_of_students') }}</p>
                                                    </div>
                                                    <div class="card-bottom">
                                                        <h3>{{ search.scholarship_type_id === 1 ? $n(totPrimarySchoolsStudents) : $n(schoolsStudents) }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                         </router-link>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
    mixins: [teaGardenService],
    data () {
        return {
            search: {
                scholarship_type_id: 1
            },
            numberOfSchools: 0,
            schoolsStudents: 0,
            totPrimarySchools: 0,
            totPrimarySchoolsStudents: 0
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        scholarshipTypeList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'primary' : 'প্রাথমিক' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'secondary' : 'মাধ্যমিক' }
            ]
            return list
        },
        loading () {
            return this.$store.state.commonObj.loading
        }
    },
    methods: {
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/education-trust-scholarship', this.search)
            if (result.success) {
                this.numberOfSchools = result.numberOfSchools
                this.schoolsStudents = result.schoolsStudents
                this.totPrimarySchools = result.totPrimarySchools
                this.totPrimarySchoolsStudents = result.totPrimarySchoolsStudents
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>
